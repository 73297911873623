<template>
  <div class="character-profile-editor-actions position-relative">
    <div class="justify-content-center d-flex">
      <ion-button
        v-if="showDraftPrivacy"
        class="btn mr-3 save-draft-button"
        :disabled="action === 'saveAsDraft' && loading"
        @click="doSaveAsDraft"
      >
        <ChLoading size="sm" v-if="action === 'saveAsDraft' && loading" class="spinner" />
        <div v-else class="white">Save as Draft</div>
      </ion-button>
      <ion-button
        v-if="get(user, 'is_email_verified', true) || character?.info?.privacy === 'M'"
        class="btn"
        :disabled="action === 'publish' && loading"
        @click="publish(false)"
      >
        <ChLoading size="sm" v-if="action === 'publish' && loading" class="spinner" />
        <div v-else class="no-select">{{ actionName }}</div>
      </ion-button>
      <VerificationButton v-else />
    </div>
    <span v-if="!template && !isNew" class="d-flex justify-content-center mt-4">
      <span class="delete-btn" @click="doDelete">Delete</span>
    </span>
    <VisualPreviewModal
      v-model:caption="visImageCaption"
      v-model:source="visImageSource"
      :image-url="selectedVisualImg"
      :is-open="openVisualPreviewModal"
      :is-create="isCreatePreviewModal"
      :modal-title="visualPrevModalTitle"
      @dismiss-modal="closeVisualPreviewModal"
    />

    <CharacterCreationModal :is-open="isOpenFirstCharacterCreationModal" @close="closeCreationAndOpenCbModal" />

    <ComplimentBombPopUp :is-open="isOpenCbModal" @close="closeCbModal" :pack="product" :slug="characterSlug" />
  </div>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import {
  createSaveDraft,
  deleteCharacter,
  deleteCharacterDraft,
  editCharacterSaveDraft,
} from '@/shared/actions/characters';
import { toast } from '@/shared/native';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { characterEditorStore, useCharacterEditor } from '@/shared/pinia-store/character-editor';
import { imageStore, useImageStore } from '@/shared/pinia-store/images';
import constants from '@/shared/statics/constants';
import { getGemPackageListings } from '@/shared/actions/payments';
import store from '@/shared/store';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';
import logger from '@/shared/services/logger';
import { isYoutubeVideoLinkValid } from '@/shared/utils/string';
import VerificationButton from '@/shared/components/VerificationButton.vue';
import { authStore } from '@/shared/pinia-store/auth';
import CharacterCreationModal from '@/shared/modals/CharacterCreationModal.vue';
import ComplimentBombPopUp from '@/shared/modals/ComplimentBombPopUp.vue';
const { user, getUserCharacters } = authStore();

const {
  character,
  isTemplate,
  template,
  isDraft,
  clear,
  changeKey,
  currentImageCollections,
  currentImageCollectionsChanged,
  mutatedInnerRelationships,
  changeInfoKey,
  commitTriviaChanges,
  didUserCreateCharacter,
  commitOutfitsChanges,
  autosaveDraft,
  autosaveCharacter,
  autosavePreset,
} = characterEditorStore();

const { commitAllChanges } = imageStore();
const id = computed(() => character?.value?.id);
const loading = ref(false);
const action = ref('');
const router = useRouter();
const stopWatch = ref(null as any);
const stopWatchImg = ref(null as any);
const isNew = computed(() => !id.value);
const isOpenCbModal = ref(false);
const isOpenFirstCharacterCreationModal = ref(false);
const packages = ref([]);
const code = ref('special-pack');
const packageName = ref('special');
const product = ref({});
const closeVisualPreviewModal = (redirectAfterPrevClose: string = '') => {
  openVisualPreviewModal.value = false;
  selectedVisualImg.value = '';
  visualPrevModalTitle.value = 'Image Preview';

  selectedVisIndex.value = -1;
  visImageCaption.value = '';
  visImageSource.value = '';

  if (redirectAfterPrevClose) {
    router.push({
      name: 'character-profile-new',
      params: { slug: redirectAfterPrevClose },
    });
  }
};

const emit = defineEmits(['done']);

const spotifyLink = computed(() => {
  return character.value?.info?.spotifyLink as any;
});

const actionName = computed(() => {
  return character?.value?.info?.privacy === 'M' || isTemplate.value ? 'Save' : 'Publish';
});

const selectedVisualImg = ref('');
const visualPrevModalTitle = ref('');
const isCreatePreviewModal = ref(false);
const visImageCaption = ref('');
const visImageSource = ref('');
const visImageArea = ref('');
const selectedVisIndex = ref(-1);
const characterSlug = ref('');
const openVisualPreviewModal = ref(false);

const openVisualPreview = (
  imgUrl: string,
  modalTitle = 'Image Preview',
  caption = '',
  source = '',
  isCreate = true,
  visIndex = 0,
  area = 'visuals'
) => {
  selectedVisualImg.value = imgUrl;
  visualPrevModalTitle.value = modalTitle;
  isCreatePreviewModal.value = isCreate;
  visImageCaption.value = caption;
  visImageSource.value = source;
  visImageArea.value = area;
  selectedVisIndex.value = visIndex;
  openVisualPreviewModal.value = true;
};

const doDelete = async () => {
  loading.value = true;
  action.value = 'delete';
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to delete this character.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const router = useRouter();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      emit('done');
      if (id.value) {
        if (isDraft.value) {
          await deleteCharacterDraft(id.value);
          router.push({ name: 'drafts' });
        } else {
          await deleteCharacter(id.value);
          router.push({ name: 'my-creations' });
        }
      }
      toast.show('Character deleted successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const showDraftPrivacy = computed(() => {
  const { name } = useRoute();
  if (name === 'draft-character-editor' || (name === 'character-creator' && (isDraft.value || !id.value))) {
    return true;
  }

  return false;
});

const doSaveAsDraft = () => {
  changeKey('privacy', 'D');
  action.value = 'saveAsDraft';
  publish(true);
};

// publishing either means saving a Preset, or a CharacterDraft, or a Character
const _savePreset = async () => {
  const router = useRouter();
  const privacy = 'P';
  let response;
  let visualCollectionIds = currentImageCollectionsChanged.value
    ? currentImageCollections.value?.map((x) => x.id as string)
    : null;
  if (isNew.value) {
    visualCollectionIds = await commitAllChanges({});
    commitOutfitsChanges({});
    await commitTriviaChanges({});
    response = await createSaveDraft(
      character.value?.info,
      character.value?.is_nsfw,
      visualCollectionIds,
      character.value?.character_collections,
      character.value?.info?.tags,
      'template',
      {
        template_title: character.value?.template_title,
        template_desc: character.value?.template_desc,
        allow_roleplay_req: character.value?.allow_roleplay_req,
        allow_comp_bomb: character.value?.allow_comp_bomb,
        privacy,
      }
    );
    router.push({ name: 'presets' });
    getUserCharacters();
    clear();
  } else {
    await commitAllChanges({ id: id.value!, model: 'character_draft' });
    commitOutfitsChanges({ id: id.value!, model: 'character_draft' });
    await commitTriviaChanges({ id: id.value!, model: 'characterdraft' });
    response = await editCharacterSaveDraft(
      id.value,
      character.value?.is_nsfw,
      character.value?.info,
      visualCollectionIds,
      character.value?.character_collections,
      character.value?.info?.tags,
      'template',
      {
        template_title: character.value?.template_title,
        template_desc: character.value?.template_desc,
        allow_roleplay_req: character.value?.allow_roleplay_req,
        allow_comp_bomb: character.value?.allow_comp_bomb,
        privacy,
        original_creator: didUserCreateCharacter.value ? '' : character.value?.original_creator,
      }
    );
    getUserCharacters();
    router.push({ name: 'presets' });
    clear();
  }
  return response;
};

const _saveDraft = async () => {
  const router = useRouter();
  let response;
  let visualCollectionIds = currentImageCollectionsChanged.value
    ? currentImageCollections.value?.map((x) => x.id as string)
    : null;
  if (isNew.value) {
    visualCollectionIds = await commitAllChanges({});
    commitOutfitsChanges({});
    await commitTriviaChanges({});
    response = await createSaveDraft(
      character.value?.info,
      character.value?.is_nsfw,
      visualCollectionIds,
      character.value?.character_collections,
      character.value?.info?.tags,
      'draft',
      {
        allow_roleplay_req: character.value?.allow_roleplay_req,
        allow_comp_bomb: character.value?.allow_comp_bomb,
        original_creator: didUserCreateCharacter.value ? '' : character.value?.original_creator,
      }
    );
    router.push({
      name: 'character-profile-draft-new',
      params: { id: response.id },
    });
    clear();
  } else {
    visualCollectionIds = await commitAllChanges({ id: id.value!, model: 'character_draft' });
    commitOutfitsChanges({ id: id.value!, model: 'character_draft' });
    await commitTriviaChanges({ id: id.value!, model: 'characterdraft' });
    response = await editCharacterSaveDraft(
      id.value,
      character.value?.is_nsfw,
      character.value?.info,
      visualCollectionIds,
      character.value?.character_collections,
      character.value?.info?.tags,
      'draft',
      {
        allow_roleplay_req: character.value?.allow_roleplay_req,
        allow_comp_bomb: character.value?.allow_comp_bomb,
        original_creator: didUserCreateCharacter.value ? '' : character.value?.original_creator,
      }
    );
    router.push({
      name: 'character-profile-draft-new',
      params: { id: response.id },
    });
    clear();
  }
};

const syncRelationships = () => {
  const relationshipsOrder = mutatedInnerRelationships.value.map((id) => String(id));

  changeInfoKey('relationshipsSection', relationshipsOrder);
};
const fetchGemsPackages = async () => {
  const resp = await getGemPackageListings();
  packages.value = resp[packageName.value];
  product.value = packages.value.find((item: any) => item.code === code.value) || {};
};
const closeCbModal = (isNewChar = 1) => {
  router.push({ name: 'character-profile-new', params: { slug: characterSlug.value, new: isNewChar } });
  getUserCharacters();
  clear();
};

const charFirstCreationModal = async () => {
  sendAnalyticsEvent('Opened starter pack popup on first character');
  await fetchGemsPackages();
  isOpenFirstCharacterCreationModal.value = true;
};
const _publishCharacter = async () => {
  const router = useRouter();
  await syncRelationships();
  const privacy = character?.value?.privacy;
  let response;
  let visualCollectionIds = currentImageCollectionsChanged.value
    ? currentImageCollections.value?.map((x: any) => x.id as string)
    : null;
  if (isNew.value || isDraft.value) {
    visualCollectionIds = await commitAllChanges({});
    commitOutfitsChanges({});
    await commitTriviaChanges({});
    response = await store.dispatch('CharactersModule/create', {
      charData: character.value?.info,
      allow_roleplay_req: character.value?.allow_roleplay_req,
      allow_comp_bomb: character.value?.allow_comp_bomb,
      is_nsfw: character.value?.is_nsfw,
      visual_collections: visualCollectionIds,
      character_collections: character.value?.character_collections,
      charId: character.value?.id,
      tags: character.value?.info?.tags,
      original_creator: didUserCreateCharacter.value ? '' : character.value?.original_creator,
    });
    characterSlug.value = get(response, 'slug');
    if (get(response, 'first_pub_char')) {
      charFirstCreationModal();
    } else {
      closeCbModal();
    }
  } else {
    visualCollectionIds = await commitAllChanges({ id: id.value!, model: 'character' });

    commitOutfitsChanges({ id: id.value!, model: 'character' });
    await commitTriviaChanges({ id: id.value!, model: 'character' });
    response = await store.dispatch('CharactersModule/edit', {
      ...character.value,
      privacy,
      character_collections: character.value?.character_collections,
      visual_collections: visualCollectionIds,
      tags: character.value?.info?.tags,
      original_creator: didUserCreateCharacter.value ? '' : character.value?.original_creator,
    });
    characterSlug.value = get(response, 'slug');
    if (get(response, 'first_pub_char')) {
      charFirstCreationModal();
    } else {
      closeCbModal(0);
    }
  }
};

const closeCreationAndOpenCbModal = (val: any) => {
  isOpenFirstCharacterCreationModal.value = false;

  get(val, 'detail.role') === 'backdrop' ? (isOpenCbModal.value = true) : (isOpenCbModal.value = true);
};

const publish = async (savingAsDraft = false) => {
  loading.value = true;
  action.value = 'publish';
  emit('done');
  try {
    if (!character.value?.info?.name && !isTemplate.value) {
      await toast.show('Character name is required', 'nonative', 'danger');
      return;
    }
    if (isTemplate.value && !character.value?.template_title) {
      await toast.show('Template title is required', 'nonative', 'danger');
      return;
    }

    for (let link of spotifyLink.value || []) {
      if (
        get(link, 'link') &&
        !constants.spotifyLinkRegex.test(get(link, 'link')) &&
        !isYoutubeVideoLinkValid(get(link, 'link'))
      ) {
        await toast.show(
          'Invalid music link. Please enter a Spotify playlist, album or track link, or a Youtube video link.',
          'nonative',
          'danger'
        );
        return;
      }
    }

    if (character.value && !character.value.allow_roleplay_req) character.value.allow_roleplay_req = false;
    if (character.value && !character.value.allow_comp_bomb) character.value.allow_comp_bomb = false;
    if (isTemplate.value) await _savePreset();
    else if (savingAsDraft) await _saveDraft();
    else await _publishCharacter();

    const hasMoreThan9kPower = !!Object.values(character.value?.info?.stats || {}).find(
      (item) => item.displayType === 'bar' && toNumber(item.value) > 9000
    );

    if (isDraft.value && hasMoreThan9kPower) {
      openVisualPreview('/images/over9000.gif', 'Over 9000!!!', '', '', false, 0, 'visuals');
    }

    await toast.show('Successfully saved.', 'nonative', 'success');
  } catch (_err: any) {
    if (_err.response?.status === 400 && _err.response?.data.err_code === 'cannot_update') {
      toast.show('You cannot publish NSFW content.', 'nonative', 'danger');
    } else if (!isTemplate.value) {
      logger.error({
        e: _err,
        loc: 'character-editor.publish',
        msg: 'Error while publishing',
        data: {
          character: character.value,
          savingAsDraft,
          isTemplate: isTemplate.value,
        },
      });
      await toast.show('Some error has occurred. Please try again.', 'nonative', 'danger');
      throw _err;
    }
  } finally {
    loading.value = false;
  }
};

const _autosave = () => {
  const { isDirty, autosaveEnabled } = characterEditorStore();
  if (!autosaveEnabled.value) return;
  if (!isDirty.value) return;
  if (isOpenFirstCharacterCreationModal.value) {
    return;
  } else if (isOpenCbModal.value) {
    return;
  } else if (isTemplate.value) autosavePreset();
  else if (isDraft.value) autosaveDraft();
  else autosaveCharacter();
};
const callAutosave = debounce(_autosave, 3000, { leading: false, trailing: true });

onMounted(() => {
  const characterEditorStoreToWatch = useCharacterEditor();
  const imageStoreToWatch = useImageStore();

  setTimeout(() => {
    stopWatch.value = watch(() => characterEditorStoreToWatch.$state, callAutosave, { deep: true });
    stopWatchImg.value = watch(() => imageStoreToWatch.$state, callAutosave, { deep: true });
  }, 5000);
});
onBeforeUnmount(() => {
  if (stopWatch.value) stopWatch.value();
  if (stopWatchImg.value) stopWatchImg.value();
});
</script>

<style lang="sass" scoped>
.spinner
  width: 20px
  height: 20px
.character-profile-editor-actions
  margin-bottom: 30px
  .btn
    --border-radius: 10px
    text-transform: unset
    min-width: 150px
.white
  color: white !important
.delete-btn
  background-color: transparent
  color: gray
  cursor: pointer
  padding: 0 0.5rem 0.5rem 0.5rem
  user-select: none !important
.delete-btn:hover
  text-decoration: underline
.save-draft-button
  --background: #00b4c5 !important
</style>
