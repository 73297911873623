<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    style="background: #3b3a53ad; --box-shadow: 0px 4px 0px 0px rgba(141, 112, 208)"
    @didDismiss="dismissModal"
  >
    <div class="inner-content p-3">
      <div class="title">
        Unlock <span class="text-primary">Stunning Decorations</span> and a
        <span class="text-primary">Rare Profile Ring</span> for Your Character!
      </div>

      <small class="sub-title">Limited-time special bundle includes:</small>
      <div class="p-2 my-2 section">
        <div class="d-flex align-items-center align-items-baseline">
          <div v-for="(img, index) in imagesFromProductFeatureTuples">
            <div class="images">
              <img :src="img" />
              <span v-if="quantities[index]" class="quantity">x{{ quantities[index] }}</span>
            </div>

            <div class="name">{{ nameFromProductFeatureTuples[index] }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column">
        <ion-button class="get-btn clickable-item-hov" @click="goToShopPaymentMethod">
          Get it for only
          <span class="mx-1"> ${{ ((get(pack, 'discounted_amount') || get(pack, 'amount')) / 100).toFixed(2) }} </span>
        </ion-button>
        <div class="limit-badge d-flex justify-content-center">
          <small>1 time purchase</small>
        </div>
        <ion-button color="transparent" class="thank-btn" @click="dismissModal">No ,Thanks </ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { useImageTuple } from '~/composables/useImageTuple';
import { useShopProductsFeature } from '~/composables/useShopProductsFeature';
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  pack: {
    type: Object,
    required: true,
  },
  slug: {
    type: String,
    default: '',
  },
});
const isOpen = toRef(props, 'isOpen');
const pack = toRef(props, 'pack');
const emits = defineEmits(['close']);
const router = useRouter();
const slug = toRef(props, 'slug');

const goToShopPaymentMethod = () => {
  router.push({ name: 'shop', query: { bundle: pack.value.code, redirectCharSlug: slug.value } });
};

const dismissModal = () => {
  emits('close');
};

const productsImages = computed(() => {
  const { productFeatureTuples } = useShopProductsFeature(pack.value);
  return productFeatureTuples.value;
});
const imagesFromProductFeatureTuples = computed(() => {
  const extraImages = pack.value?.more_preview_images || ([] as string[]);
  const imgs = productsImages.value
    .map((tup: any[]) => {
      const { imageFromTuple } = useImageTuple(tup);
      return imageFromTuple();
    })
    .filter((img: any) => img) as string[];

  const out = [...imgs, ...extraImages];

  return out;
});

const nameFromProductFeatureTuples = computed(() => {
  const imgs = productsImages.value.map((tup: any[]) => {
    const [type] = tup;
    if (type === 'amethyst') {
      return 'Gem';
    } else if (type === 'cbomb') {
      return 'Compliment Bomb';
    } else if (type === 'hubux') {
      return 'Hubux';
    } else if (type === 'others') {
      return 'Profile Ring';
    }
    return null;
  });
  const out = [...imgs];
  return out;
});

const quantities = computed(() => {
  const { quantitiesFromProductFeatureTuples } = useShopProductsFeature(pack.value);
  return quantitiesFromProductFeatureTuples.value;
});
</script>

<style lang="sass" scoped>
.limit-badge
  width: 100px
  color: #214163 !important
  small
    font-size: 11px
.section
  border: 2px solid white
  border-radius: 11px
.get-btn
  --background: linear-gradient(to right ,rgb(189 71 216) 21%,rgb(108 83 206) 73%,rgb(94 93 206) 98%,rgb(86 98 206) 100%)
  opacity: 1
  font-weight: bolder
  font-size:20px
  height: 44px
  --border-radius: 11px
  animation: gradient 4s ease infinite
  transition: transform 0.2s
  @media(max-width:1460px)
    height: 33px
.get-btn:after
  content: ""
  position: absolute
  top: 4px
  left: 4px
  width: calc(100% - 8px)
  height: 50%
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))
  border-radius: 10px
@keyframes gradient
  50%
    background-position: 140% 50%
    transform: skew(-2deg)

.thank-btn
  text-decoration: underline
  color: #ae38e5
.quantity
  position: absolute
  right: 13px
  bottom: 1px
  z-index: 1
  font-family: 'Roboto'
  font-weight: 900
  font-size: 1.25rem
  background: linear-gradient(90deg, rgba(255, 250, 234, 1), rgba(255, 212, 73, 1))
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  background-clip: text
  text-fill-color: transparent
  -webkit-text-stroke-width: 1px
  -webkit-text-stroke-color: rgba(67, 28, 137, 1)
  @media (max-width: 419px)
    font-size: 1.15rem
    -webkit-text-stroke-width: 1px
    -webkit-text-stroke-color: rgba(67, 28, 137, 0.75)
.name
  color: #431c89
  text-align: center
  font-weight: 700
.images
  position: relative
  display: flex
  margin: auto 0.3rem auto 0
  img
    border-radius: 16px
    background-color: rgba(255, 255, 255, 0.5)
    margin: auto 0
    padding: 0.3rem
    min-height: 99px !important
    max-height: 99px !important
    border:5px solid #f5defc
    width: 130px
    height: 100% !important
    aspect-ratio: 1 !important
    @media (max-width: 419px)
      aspect-ratio: 1 !important


.dark .toolbar
  --background: #010123 !important

.modal-big
  --width: 400px
  --height: auto
  --border-radius: 9px

.title
  font-weight: bold
  font-size: 22px
  color: #431c89
.dark .title
  color: #431c89 !important
.sub-title
  color: #431c89
  font-weight: 700
.inner-content
  background-color: rgba(234, 219, 255, 1)
  max-height: 88dvh
  overflow: auto
  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    background: linear-gradient(to top, rgba(255, 164, 240, 0) 0%, rgba(255, 216, 246, 0.3) 100%)
    z-index: 0
</style>
